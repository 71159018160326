import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import store from '../store'

import Wordpress from '@/wordpress/controller'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/DashboardView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/submissions',
    name: 'Form Submissions',
    component: () => import('../views/FormSubmissionView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/media',
    name: 'Media',
    component: () => import('../views/MediaView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pages',
    name: 'Pages',
    component: () => import('../views/PagesView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/posts',
    name: 'Posts',
    component: () => import('../views/PostsView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/plugins',
    name: 'Plugins',
    component: () => import('../views/PluginsView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:notFound',
    name: 'Page Not Found',
    component: () => import('../views/PageNotFoundView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {

  await Wordpress.isStillAuth();

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  //* CONDITIONAL ROUTES

  if (requiresAuth && !store.getters.isAunthenticated) {

    next({ path: '/' });

  } else if (to.name === 'Login' && store.getters.isAunthenticated) {

    next({ path: '/dashboard' })

  } else {

    next();

  }

});

export default router
