import axios from 'axios';
import store from '@/store'
import router from '@/router';

const url = 'https://creativepearagency.com/wp-json';
const webUrl = 'https://creativepearagency.com'
const autoLoginCode = '8YBVRCzjIsHgQCy4DLW2AZbaZBNemjC3'

class Wordpress {

    static async login(username, password) {

        try {

            store.dispatch('ADD_LOADING_ACTION');

            const tokenData = await axios.post(`${url}/jwt-auth/v1/token/`, {
                username,
                password
            });

            const userData = await axios.get(`${url}/wp/v2/users/me/`, {
                headers: {
                    Authorization: `Bearer ${tokenData.data.token}`
                },
                params: {
                    context: 'edit'
                }
            });
    
            if(userData.data.roles.includes("administrator")) {

                store.dispatch('SET_AUTH_ACTION', true);
                store.dispatch('SET_TOKEN_ACTION', tokenData.data.token);
                store.dispatch('SET_USER_DATA_ACTION', {
                    userName: userData.data.username,
                    userEmail: userData.data.email,
                    userRegisteredDate: userData.data.registered_date,
                    userProfilePicture: userData.data.avatar_urls['96'],
                    webUrl,
                    autoLoginCode
                });
                store.dispatch('REMOVE_LOADING_ACTION');
                router.replace('dashboard');
    
            } else {

                throw new Error();

            }

        } catch (error) {

            store.dispatch('REMOVE_LOADING_ACTION');
            throw new Error('Invalid username or password.');

        }

    }

    static async isStillAuth() {

        try {

            store.dispatch('ADD_LOADING_ACTION');

            if(store.getters.isAunthenticated) {

                await axios.post(`${url}/jwt-auth/v1/token/validate/`, null, {
                    headers: {
                        Authorization: `Bearer ${store.getters.getToken}`
                    }
                });

            } else {

                throw new Error();

            }

        } catch (error) {

            store.dispatch('SET_AUTH_ACTION', false);
            store.dispatch('SET_TOKEN_ACTION', undefined);
            store.dispatch('SET_USER_DATA_ACTION', undefined);
            store.dispatch('REMOVE_LOADING_ACTION');
            store.dispatch('CLOSE_MOBILE_MENU_ACTION');

        }

    }

    static async logout() {

        store.dispatch('SET_AUTH_ACTION', false);
        store.dispatch('SET_TOKEN_ACTION', undefined);
        store.dispatch('SET_USER_DATA_ACTION', undefined);
        store.dispatch('CLOSE_MOBILE_MENU_ACTION');
        router.replace('/');

    }

    static async createInstance() {

        const instance = axios.create({
            baseURL: url,
            headers: {
                Authorization: `Bearer ${store.getters.getToken}`
            }
        });

        return instance;

    }

    static async getDirectoryInfo() {

        try {

            store.dispatch('ADD_LOADING_ACTION');

            const instance = await this.createInstance();
            const response = await instance.get('/wp-site-health/v1/directory-sizes/');

            store.dispatch('REMOVE_LOADING_ACTION');
            
            return response.data;

        } catch (error) {

            store.dispatch('REMOVE_LOADING_ACTION');
            this.logout();
            throw new Error(error.message);

        }

    }

    static async getHealthInfo() {

        try {

            store.dispatch('ADD_LOADING_ACTION');

            const instance = await this.createInstance();
            const response1 = await instance.get('/wp-site-health/v1/tests/background-updates/');
            const response2 = await instance.get('/wp-site-health/v1/tests/loopback-requests/');
            const response3 = await instance.get('/wp-site-health/v1/tests/https-status/');
            const response4 = await instance.get('/wp-site-health/v1/tests/dotorg-communication/');
            const response5 = await instance.get('/wp-site-health/v1/tests/authorization-header/');
            const response6 = await instance.get('/wp-site-health/v1/tests/page-cache/');

            store.dispatch('REMOVE_LOADING_ACTION');

            return [
                response1.data,
                response2.data,
                response3.data,
                response4.data,
                response5.data,
                response6.data
            ];

        } catch (error) {

            store.dispatch('REMOVE_LOADING_ACTION');
            this.logout();
            throw new Error(error.message);

        }

    }

    static async getFormSubmissions(itemsPerPage, currentPage) {

        try {

            store.dispatch('ADD_LOADING_ACTION');

            const instance = await this.createInstance();
            const response = await instance.get('/elementor/v1/form-submissions/', {
                params: {
                    per_page: itemsPerPage,
                    page: currentPage
                }
            });

            store.dispatch('REMOVE_LOADING_ACTION');

            return response;

        } catch (error) {

            store.dispatch('REMOVE_LOADING_ACTION');
            this.logout();
            throw new Error(error.message);

        }

    }

    static async getFormSubmission(id) {

        try {

            store.dispatch('ADD_LOADING_ACTION');

            // GET SUBMISSION DATA BY ID
            const instance = await this.createInstance();
            const response = await instance.get(`/elementor/v1/form-submissions/${id}`);

            // MARK READ SUBMISSION
            await instance.patch('/elementor/v1/form-submissions/', {
                ids: [id],
                is_read: true
            });

            store.dispatch('REMOVE_LOADING_ACTION');

            return response.data;

        } catch (error) {

            store.dispatch('REMOVE_LOADING_ACTION');
            this.logout();
            throw new Error(error.message);

        }

    }

    static async getMedia(itemsPerPage, currentPage, searchText) {

        try {

            store.dispatch('ADD_LOADING_ACTION');

            const instance = await this.createInstance();
            const response = await instance.get('/wp/v2/media/', {
                params: {
                    context: 'edit',
                    status: 'inherit',
                    per_page: itemsPerPage,
                    page: currentPage,
                    search: searchText
                }
            });

            store.dispatch('REMOVE_LOADING_ACTION');

            return response;
            
        } catch (error) {

            store.dispatch('REMOVE_LOADING_ACTION');
            this.logout();
            throw new Error(error.message);
            
        }

    }

    static async uploadMedia(selectedFile) {

        try {

            store.dispatch('ADD_LOADING_ACTION');

            const formData = new FormData();
            formData.append('file', selectedFile);

            const instance = await this.createInstance();
            const response = await instance.post('/wp/v2/media/', formData);

            store.dispatch('REMOVE_LOADING_ACTION');

            return response;
            
        } catch (error) {

            store.dispatch('REMOVE_LOADING_ACTION');
            throw new Error(error.message);
            
        }

    }

    static async deleteMedia(id) {

        try {

            store.dispatch('ADD_LOADING_ACTION');

            const instance = await this.createInstance();
            const response = await instance.delete(`/wp/v2/media/${id}`, {
                params: {
                    force: 1
                }
            });

            store.dispatch('REMOVE_LOADING_ACTION');

            return response;
            
        } catch (error) {

            store.dispatch('REMOVE_LOADING_ACTION');
            throw new Error(error.message);
            
        }

    }

    static async getTotalCounts() {

        try {

            store.dispatch('ADD_LOADING_ACTION');

            const instance = await this.createInstance();

            const pagesResponse = await instance.get('/wp/v2/pages/', {
                params: {
                    context: 'edit',
                    status: 'any',
                    per_page: 1
                }
            });

            const postsResponse = await instance.get('/wp/v2/posts/', {
                params: {
                    context: 'edit',
                    status: 'any',
                    per_page: 1
                }
            });

            const mediaResponse = await instance.get('/wp/v2/media/', {
                params: {
                    context: 'edit',
                    status: 'inherit',
                    per_page: 1
                }
            });

            const pluginsResponse = await instance.get('/wp/v2/plugins/', {
                params: {
                    context: 'edit'
                }
            });

            const submissionsResponse = await instance.get('/elementor/v1/form-submissions/', {
                params: {
                    per_page: 1
                }
            });

            const usersResponse = await instance.get('/wp/v2/users/', {
                params: {
                    context: 'edit',
                    per_page: 1
                }
            });

            store.dispatch('REMOVE_LOADING_ACTION');

            return [
                {
                    icon: 'ri-pages-line',
                    title: 'Pages',
                    total: Number(pagesResponse.headers['x-wp-total']).toLocaleString('en-US')
                },
                {
                    icon: 'ri-newspaper-line',
                    title: 'Posts',
                    total: Number(postsResponse.headers['x-wp-total']).toLocaleString('en-US')
                },
                {
                    icon: 'ri-gallery-line',
                    title: 'Media',
                    total: Number(mediaResponse.headers['x-wp-total']).toLocaleString('en-US')
                },
                {
                    icon: 'ri-puzzle-2-line',
                    title: 'Plugins',
                    total: pluginsResponse.data.length
                },
                {
                    icon: 'ri-survey-line',
                    title: 'Submissions',
                    total: submissionsResponse.data.meta.pagination.total.toLocaleString('en-US')
                },
                {
                    icon: 'ri-group-line',
                    title: 'Users',
                    total: Number(usersResponse.headers['x-wp-total']).toLocaleString('en-US')
                }
            ];
            
        } catch (error) {

            store.dispatch('REMOVE_LOADING_ACTION');
            this.logout();
            throw new Error(error.message);
            
        }

    }

    static async getPages(itemsPerPage, currentPage) {

        try {

            store.dispatch('ADD_LOADING_ACTION');

            const instance = await this.createInstance();
            const response = await instance.get('/wp/v2/pages/', {
                params: {
                    context: 'edit',
                    status: 'any',
                    per_page: itemsPerPage,
                    page: currentPage
                }
            });

            store.dispatch('REMOVE_LOADING_ACTION');
            
            return response;

        } catch (error) {

            store.dispatch('REMOVE_LOADING_ACTION');
            this.logout();
            throw new Error(error.message);
            
        }

    }

    static async getPosts(itemsPerPage, currentPage) {

        try {

            store.dispatch('ADD_LOADING_ACTION');

            const instance = await this.createInstance();
            const response = await instance.get('/wp/v2/posts/', {
                params: {
                    context: 'edit',
                    status: 'any',
                    per_page: itemsPerPage,
                    page: currentPage
                }
            });

            store.dispatch('REMOVE_LOADING_ACTION');
            
            return response;

        } catch (error) {

            store.dispatch('REMOVE_LOADING_ACTION');
            this.logout();
            throw new Error(error.message);
            
        }

    }

    static async deletePost(id) {

        try {

            store.dispatch('ADD_LOADING_ACTION');

            const instance = await this.createInstance();
            const response = await instance.delete(`/wp/v2/posts/${id}`, {
                params: {
                    force: 1
                }
            });

            store.dispatch('REMOVE_LOADING_ACTION');

            return response;
            
        } catch (error) {

            store.dispatch('REMOVE_LOADING_ACTION');
            throw new Error(error.message);
            
        }

    }

    static async getPlugins() {

        try {

            store.dispatch('ADD_LOADING_ACTION');

            const instance = await this.createInstance();
            const response = await instance.get('/wp/v2/plugins/', {
                params: {
                    context: 'edit'
                }
            });

            store.dispatch('REMOVE_LOADING_ACTION');
            
            return response;

        } catch (error) {

            store.dispatch('REMOVE_LOADING_ACTION');
            this.logout();
            throw new Error(error.message);
            
        }

    }

}

export default Wordpress