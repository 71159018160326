<template>
  <div class="sideMenu" v-show="$store.getters.getMobileMenu">
    <div class="sideMenu__info">
      <picture>
        <source media="(max-width:600px)" srcset="@/assets/favicon.png">
        <img class="sideMenu__info__image" src="@/assets/logo.png" alt="Logo">
      </picture>
    </div>
    <nav class="sideMenu__nav">
      <router-link class="sideMenu__nav__item" to="/" v-if="!$store.getters.isAunthenticated"><i class="ri-login-circle-line"></i><span class="sideMenu__nav__item_text">Login</span></router-link>
      <router-link class="sideMenu__nav__item" to="/dashboard" v-if="$store.getters.isAunthenticated"><i class="ri-dashboard-3-line"></i><span class="sideMenu__nav__item_text">Dashboard</span></router-link>
      <router-link class="sideMenu__nav__item" to="/submissions" v-if="$store.getters.isAunthenticated"><i class="ri-survey-line"></i><span class="sideMenu__nav__item_text">Submissions</span></router-link>
      <router-link class="sideMenu__nav__item" to="/posts" v-if="$store.getters.isAunthenticated"><i class="ri-article-line"></i><span class="sideMenu__nav__item_text">Posts</span></router-link>
      <router-link class="sideMenu__nav__item" to="/pages" v-if="$store.getters.isAunthenticated"><i class="ri-pages-line"></i><span class="sideMenu__nav__item_text">Pages</span></router-link>
      <router-link class="sideMenu__nav__item" to="/media" v-if="$store.getters.isAunthenticated"><i class="ri-image-line"></i><span class="sideMenu__nav__item_text">Media</span></router-link>
      <router-link class="sideMenu__nav__item" to="/plugins" v-if="$store.getters.isAunthenticated"><i class="ri-puzzle-line"></i><span class="sideMenu__nav__item_text">Plugins</span></router-link>
      <a class="sideMenu__nav__item" @click="logoutRequest" v-if="$store.getters.isAunthenticated"><i class="ri-logout-circle-line"></i><span class="sideMenu__nav__item_text">Logout</span></a>
    </nav>
  </div>
</template>

<script>
import Wordpress from '@/wordpress/controller'

export default {
  name: 'SideMenu',
  methods: {
    logoutRequest() {

      Wordpress.logout();

    }
  }
}
</script>

<style scoped>
.sideMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: var(--sideMenuWidth);
  background: var(--primary__color);
  overflow-x: hidden;
}
.sideMenu__info {
  background-color: #00000018;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 150px;
  padding: 20px;
}
.sideMenu__info__image {
  width:100%;
  max-width: 200px;
}
.sideMenu__info__title {
  text-align: center;
  font-size: 1.2rem;
  color: var(--third__color);
  font-weight: bold;
  margin-top: 10px;
}
.sideMenu__nav {
  display: flex;
  flex-direction: column;
  padding: 10px 0px 10px 10px;
}
.sideMenu__nav__item {
  padding: 15px 20px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  text-decoration: none;
  color: var(--third__color);
  margin-top: 20px;
  transition: background-color .1s ease-in-out, color .1s ease-in-out;
  display: flex;
  align-items: center;
}
.sideMenu__nav__item > i {
  font-size: 20px;
  margin-right: 10px;
}
.sideMenu__nav__item:hover {
  background-color: var(--third__color);
  color: var(--primary__color);
}
.router-link-exact-active {
  background-color: var(--third__color);
  color: var(--primary__color);
  font-weight: 500;
}
@media only screen and (max-width: 600px) {
  .sideMenu__info {
    padding: 15px;
    min-height: 80px;
  }
  .sideMenu__nav {
    padding: 0px;
  }
  .sideMenu__nav__item {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding: 15px 10px;
    font-size: 1rem;
    display: flex;
    justify-content: center;
  }
  .sideMenu__nav__item > i {
    font-size: 22px;
    margin-right: 5px;
  }
  .sideMenu__nav__item_text {
    display: none;
  }
}
</style>
